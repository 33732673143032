const UIUXData = [
  {
    core: [
      {
        id: "1",
        name: "Product Design by Google",
        karma: "400",
        description:
          "It’s centered around the idea of product validation and UX through Design Sprints and offers a nice mix of theory and practice on the topics. A robust program that covers 4 main areas : ideation and validation, UX/UI design, Design Sprints, and key metrics.",
        link: "https://eu.udacity.com/course/product-design--ud509",
        inner_description:
          "After course completion, post the assignment url in the #ui-ux channel of the MuLearn Discord server with the hashtag #ux-intro to avail karma points.",
        assignment: [
          {
            assignment_description:
              "Rhea leads sales and business development for a Fortune 500 company. She travels 3 out of 4 weeks in the month, and when she travels, it’s almost always internationally. Between all her travel to different time zones and a busy meeting schedule in different cities, she finds it challenging to keep up with her calendar to show up to the right places at the right time. Design a calendar flow and interface that is smart enough to suggest meeting times, accounts for changing time zones and is proactively working for Rhea to make sure she is always on time for her meetings.The proposed solution has to be mobile-friendly and has to use viable technologies from today.Submit your assignment link in #ui-ux channel in MuLearn Discord server with hashtag #ui-product-design to claim Karma Points",
            assignment_inner_description:
              "Submit your assignment link in #ui-ux channel in MuLearn Discord server with hashtag #ui-product-design to claim Karma Points",
            example:
              "#ui-product-design Hey I’ve completed Product Design Course by Google. Here’s my assignment link ",
          },
        ],
      },
      {
        id: "2",
        name: "Introduction to User Experience Design",
        karma: "400",
        description:
          "The focus of this course is to introduce the learner to User Experience (UX) Design. User Experience design is user-centred. The goal is to design artifacts that allow users to meet their needs in the most effective, efficient and satisfying manner. The course introduces the novice to a cycle of discovery and evaluation and a set of techniques that meet the user's needs. Choose the Free version of the course and start learning. Let’s build awesome designs!",
        link: "https://www.coursera.org/learn/user-experience-design",
        inner_description:
          "After course completion, complete the assignment and post the assignment url in the #ui-ux channel of the µLearn Discord server with the hashtag #ux-intro to avail karma points.",
        assignment: [
          {
            assignment_description:
              "Improve Instagram with a new, innovative feature, which stands out from other image apps.",
            assignment_inner_description:
              "Submit your design file url in #ui-ux channel in MuLearn Discord server with hashtag #ux-intro to claim Karma Points",
            example:
              "#ux-intro Hey I’ve completed Introduction to User Experience course. Checkout my assignment ui-intro-to-ux",
          },
        ],
      },
    ],
    sub: [
      {
        id: "1",
        name: "Figma Crash Course",
        karma: "200",
        description:
          "Want to get started in design, but don’t know where to begin? These lessons and exercises will help you start designing immediately. Master Figma & make awesome designs",
        link: "https://www.classcentral.com/course/youtube-figma-tutorials-the-ultimate-crash-course-51794/classroom",
        inner_description:
          "After course completion, post the assignment url in the #ui-ux channel of the MuLearn Discord server with the hashtag #ui-figma to avail karma points.",
        assignment: [
          {
            assignment_description:
              "Clone any three screens of your favourite app in Figma. Ideally, it can be the home screen of the app and any other screens.",
            assignment_inner_description:
              "Submit your assignment link in #ui-ux channel in µLearn Discord server with hashtag #ui-figma to claim Karma Points",
            example:
              "#ui-figma Hey I’ve completed the Figma Assignment. Here’s my assignment url ",
          },
        ],
      },
    ],
    enablement: [
      {
        id: "1",
        name: "Create a Figma Account",
        karma: "50",
        "description ": "",
        link: "",
        inner_description: "",
      },
    ],

    lvl4: [
      {
        level: 4,
        id: 1,
        name: "Find and define a Simple Problem",
        karma: 100,
        description:
          "In this task, you are required to prepare a problem statement based on research, user needs, and business goals and design a solution that meets the needs.",
        link: "https://www.coursera.org/learn/start-ux-design-process/lecture/NC282/create-problem-statements",
        innner_description:
          "After completing this task, share the PDF or Word File in the ⁠ui-ux channel using the hashtag #cl-findaproblem",
        example:
          "#cl-findaproblem \nHey, I have completed the task and here is the link to my file",
      },
      {
        level: 4,
        id: 2,
        name: "Figma Basics",
        karma: 400,
        description:
          "In this task, you are required to go through the video provided below and clone any three screens of your favorite app in Figma. You can create the home screen and any other screens of your choice.",
        link: "https://www.classcentral.com/classroom/youtube-figma-tutorials-the-ultimate-crash-course-51794",
        innner_description:
          "After completing this task, share the Figma file in the ⁠ui-ux channel using the hashtag #cl-ui-figma",
        example:
          "#cl-ui-figma\nHey, I have completed the task and here is the figma link for my submission. [Link]",
      },
      {
        level: 4,
        id: 3,
        name: "Sun and Moon",
        karma: 400,
        description:
          "In this task, you are required to watch the video provided below and create toggle buttons on your own.",
        link: "https://youtu.be/rKtBy63cHJ4?si=fN4E1sWSHYwWijS1",
        innner_description:
          "After completing this task, share the Figma file in the ⁠ui-ux channel using the hashtag #cl-ui-sunmoon",
        example:
          "#cl-ui-sunmoon\nHey, I have completed the task and here the figma link [Link}",
      },
      {
        level: 4,
        id: 4,
        name: "Create a User Persona",
        karma: 400,
        description:
          "In this task, you are required to create personas of 3 target users and design a solution to the needs, based on research and data that provides a clear understanding of the users’ needs, goals, pain points, and motivations. Mention demographics, and users' quotes as well.",
        link: "https://www.coursera.org/learn/start-ux-design-process/lecture/ZpYoj/understand-personas",
        innner_description:
          "After completing this task, share the PDF or Word File in the ⁠ui-ux channel using the hashtag #cl-ux-persona",
        example:
          "#cl-ux-persona\nHey, I have completed the user persona task and here is my link to the word file",
      },
    ],
    lvl5: [
      {
        level: 5,
        id: 1,
        name: "Create a Competitive Audit Report",
        karma: 800,
        description:
          "In this task, you are required to assess and write a report on a company’s rival, pointing out its strengths, weaknesses, and opportunities. Examine competitor products, marketing strategies, and customer feedback. Specify recommendations to enhance the company’s position as well.",
        link: "https://www.coursera.org/learn/start-ux-design-process/lecture/JG2nZ/steps-to-conduct-a-competitive-audit",
        innner_description:
          "After completing this task, share the PDF of your report in the ⁠ui-ux channel using the hashtag #cl-ux-competitivereport",
        example:
          "#cl-ux-competitivereport\nHey, I have completed the task and here is the figma link [Link}",
      },
      {
        level: 5,
        id: 2,
        name: "Card Sorting Design Method",
        karma: 800,
        description:
          "In this task, you will learn a design technique for a user-centered, card-sorting method for organizing website content by categorizing topics into groups and aiding in website structure and navigation. \nMake a report stating the similarities, and the most confusing points you faced while sorting.",
        link: "https://youtu.be/AxgMIEVcIMY",
        innner_description:
          "After completing this task, share the PDF or an image showing all cards, and a PDF of the Report in the ⁠ui-ux channel using the hashtag #cl-ux-cardsorting",
        example:
          "#cl-ux-cardsorting\nHey, I have completed the card sorting task and here is the link to my submission [Link}",
      },
    ],
    lvl6: [
      {
        level: 6,
        id: 1,
        name: "Create a Paper Wireframe",
        karma: 200,
        description:
          "In this task, you will learn how to create paper wireframes which is a hand-drawn sketch of a digital interface showing the layout, elements, and user flow. There should be a minimum of 4 windows in the design",
        link: "https://www.coursera.org/learn/wireframes-low-fidelity-prototypes/lecture/wN5Wk/draw-your-first-wireframe",
        innner_description:
          "After completing this task, share the image as a PNG or JPEG of your paper wireframe in the ⁠ui-ux channel using the hashtag #cl-ux-paperwireframe",
        example:
          "#cl-ux-paperwireframe\nHey, I have completed the paper wireframe task and here are the images",
      },
      {
        level: 6,
        id: 2,
        name: "Create Low-Fi Prototype",
        karma: 400,
        description:
          "In this task, you are required to create and design a Low-Fi prototype of a digital wireframe you designed to represent its basic interface, focusing on functions over appearance, using Figma ONLY.",
        link: "https://youtu.be/3F4mWr1cY-8",
        innner_description:
          "After completing this task, share your Figma file URL in the ⁠ui-ux channel using the hashtag #cl-ux-lowfi",
        example:
          "#cl-ux-lowfi\nHey, I have completed the Low-Fi Wireframe task and here is my figma link [Link]",
      },
    ],
  },
];

export default UIUXData;
