const WebData = [
  {
    core: [
      {
        id: "1",
        name: "Responsive Web Design",
        karma: "800",
        description:
          "In this Responsive Web Design Certification, you'll learn the languages that developers use to build webpages: HTML (Hypertext Markup Language) for content, and CSS (Cascading Style Sheets) for design.",
        link: "https://www.freecodecamp.org/learn/2022/responsive-web-design/",
        inner_description:
          "After course completion, post the certificate in the #web-development channel of the µLearn Discord server with the hashtag #cl-web-responsive to avail karma points.",
        example:
          "#cl-web-responsive  Hey! I’ve completed Responsive Web Design Certification. Here’s is my course certificate. ",
      },
      {
        id: "2",
        name: "JavaScript Algorithms & Data Structures",
        karma: "800",
        description:
          "In the JavaScript Algorithm and Data Structures Certification, you'll learn the fundamentals of JavaScript including variables, arrays, objects, loops, and functions.",
        link: "https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/#basic-javascript",
        inner_description:
          "After course completion, post the certificate in #web-development channel of µLearn Discord Server with hashtag #cl-web-javascript to avail karma points.",
        example:
          "#cl-web-javascript Hey! I’ve completed JavaScript Algorithm and Data Structures Certification. Here’s is my course certificate. ",
      },
      {
        id: "3",
        name: "Frontend Development",
        karma: "800",
        description:
          "In the Front End Development Libraries Certification, you'll learn how to style your site quickly with Bootstrap. You'll also learn how add logic to your CSS styles and extend them with Sass.You'll build a shopping cart and other applications to learn how to create powerful Single Page Applications (SPAs) with React and Redux.",
        link: "https://www.freecodecamp.org/learn/front-end-development-libraries/",
        inner_description:
          "After course completion, post the certificate in #web-development channel of µLearn Discord server  with hashtag #cl-web-frontend to avail karma points.",
        example:
          "#cl-web-frontend Hey! I’ve completed Front End Development Libraries Certification. Here’s is my course certificate. ",
      },
      {
        id: "4",
        name: "Backend Development & API’s",
        karma: "800",
        description:
          "In the Back End Development and APIs Certification, you'll learn how to write back end apps with Node.js and npm (Node Package Manager). You'll also build web applications with the Express framework, and build a People Finder microservice with MongoDB and the Mongoose library.",
        link: "https://www.freecodecamp.org/learn/back-end-development-and-apis/#back-end-development-and-apis-projects",
        inner_description:
          "After course completion, post the certificate in #web-development channel of µLearn Discord server  with hashtag #cl-web-backend to avail karma points.",
        example:
          "#cl-web-backend Hey! I’ve completed Back End Development and APIs Certification. Here’s is my course certificate. ",
      },
      {
        id: "5",
        name: "Django - Beginner Level",
        karma: "400",
        description:
          "Django is a powerful and popular web development framework that makes it easy to build robust, scalable, and secure web applications quickly. Whether you're a beginner or an experienced programmer, this course will provide you with a solid foundation in Django and help you get started with building web applications.By the end of this course, you will build your own Task board application using Django and contribute to the thriving Django community. So let's get started and explore the wonderful world of Django!",
        link: "https://youtu.be/OTmQOjsl0eg",
        inner_description:
          "After course completion, submit the Github repository link and hosted link of your assignment in #web-development channel of µLearn Discord server with hashtag #web-task-board-django to avail 400 karma points.",
        example:
          "#web-task-board-django Hey! I’ve built Task Board Application. Here is my Github repository link and hosted link. ",
        assignment: [
          {
            assignment_description:
              "Now let’s  put our skills into action. You will be building a task board application. Click here to view more details",
            assignment_inner_description: "",
            link: "https://mulearn.notion.site/View-Assignment-2f07089d9c6b4edbb8dfbd77e43d12bd",
          },
        ],
      },
    ],
    sub: [
      {
        id: "1",
        name: "Git and GitHub",
        karma: "200",
        description:
          "Git is a piece of software that makes it simpler to manage different versions of your own software in a software repository.  GitHub is a place to share software repositories and collaborate with other developers. These tools make it easier for multiple people to work on the same software project. In this course you will learn about: Installing Git, Creating a Git Repository, Cloning a Git Repository, Adding and Removing from a Git repository, Using GitHub, creating pull requests, branching repositories, merging repositories.By the end of the course, you'll know way more about Git than the people in this web comic. :)",
        link: "https://www.youtube.com/watch?v=RGOj5yH7evk",
        inner_description:
          "Now if you go to the pull requests tab in GTech µLearn repo, you’ll be able to see your pull request. Open it and copy your pull request URL.",
        example:
          " #cl-web-git-github Submit the link in #web-and-mobile-development channel with the hashtag,",
        assignment: [
          {
            assignment_description:
              "Just like knowing how to use the Command Line, Git & Github will be very important in your daily workflow as a programmer / developer. Let’s put your Git and Github skills to practice! Find the assignment below.",
            assignment_inner_description: "",
            link: "https://mulearn.notion.site/Git-GitHub-b8a840efb00845d2a2ab1c2877b1952c",
          },
        ],
      },
    ],
    enablement: [
      {
        id: "1",
        name: "HTML Forms",
        karma: "",
        description: "Build a HTML Survey Form that",
        link: "",
        inner_description: "",
        example: "",
      },
    ],

    lvl4: [
      {
        level: "lvl4",
        id: 1,
        name: "Marking up a letter",
        karma: 400,
        description:
          "You have been tasked with marking up a letter that is a response from a research fellow to a prospective PhD student regarding their application to the university. The letter needs to be hosted on the university intranet.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML",
        innner_description: "",
        example:
          "After completing your task, share the website URL in the #web-development channel using the hashtag #cl-marking-up-a-letter.",
      },
      {
        level: "lvl4",
        id: 2,
        name: "Styling a biography page",
        karma: 200,
        description:
          "Your task is to make a Biography page using html and styling it with css",
        link: "https://developer.mozilla.org/en-US/docs/Learn/CSS/First_steps/Styling_a_biography_page",
        innner_description: "",
        example:
          "After completing your task, share the website URL in the #web-development channel using the hashtag #cl-biography-page.",
      },
      {
        level: "lvl4",
        id: 3,
        name: "Silly story generator",
        karma: 400,
        description:
          "You'll be tasked with taking some of the knowledge \nyou've picked up in this module's articles and applying it to creating a fun app that generates random silly stories",
        link: "https://developer.mozilla.org/en-US/docs/Learn/JavaScript/First_steps/Silly_story_generator",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-silly-story-generator.",
      },
      {
        level: "lvl4",
        id: 4,
        name: "Personal Portfolio",
        karma: 200,
        description:
          "Create a static site showcasing your skills, projects, and resume.",
        link: "https://www.freecodecamp.org/news/build-portfolio-website-react/",
        innner_description: "",
        example:
          "After completing this task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-react-portfolio.",
      },
      {
        level: "lvl4",
        id: 5,
        name: "ToDo List",
        karma: 200,
        description:
          "Design a simple interactive to-do list with functionalities to add, delete, and mark tasks as done.",
        link: "https://www.youtube.com/watch?v=Rh3tobg7hEo",
        innner_description: "",
        example:
          "After completing this task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-react-todo.",
      },
      {
        level: "lvl4",
        id: 6,
        name: "Tic-Tac-Toe Game",
        karma: 400,
        description:
          "Create an interactive Tic Tac Toe game using React where two players can play against each other. The game should show whose turn it is, detect when a player has won, and offer an option to restart the game.",
        link: "https://react.dev/learn/tutorial-tic-tac-toe",
        innner_description: "",
        example:
          "After completing this task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-react-tictactoe.",
      },
    ],

    lvl5: [
      {
        level: "lvl5",
        id: 1,
        name: "Mozilla splash page",
        karma: 400,
        description:
          "we'll test your knowledge of some of the techniques discussed in this module's articles, getting you to add some images and video to a funky splash page all about Mozilla!",
        link: "https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Mozilla_splash_page",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-splash-page.",
      },
      {
        level: "lvl5",
        id: 2,
        name: "Fundamental CSS comprehension",
        karma: 600,
        description:
          "The assignment for this course requires completion of a series of related exercises to create a final design, which can be either a business card, gamer card, or social media profile. The exercises are designed to test your skills and knowledge, and must be completed in order to receive a grade for the assignment",
        link: "https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Fundamental_CSS_comprehension",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-fundamental-css.",
      },
      {
        level: "lvl5",
        id: 3,
        name: "Typesetting a community school homepage",
        karma: 800,
        description:
          "The task is to demonstrate your understanding of text styling techniques by applying them to a community school's homepage. You will need to improve readability, navigation, and aesthetics of the text.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/CSS/Styling_text/Typesetting_a_homepage",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-community-homepage.",
      },
      {
        level: "lvl5",
        id: 4,
        name: "Web forms — Working with user data",
        karma: 800,
        description:
          "Build a fully functional web form to learn HTML5 input types, form controls, styling, UI pseudo-classes, client-side validation, and form data submission. Structure the form using proper HTML markup and add input fields, dropdown menus, radio buttons, checkboxes, and text areas. Apply advanced styling techniques such as UI pseudo-classes to improve user experience. Validate user input using client-side validation and submit the form data using client or server-side scripting. Successful completion of this task will demonstrate your proficiency in building and styling web forms.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/Forms",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-web-forms.",
      },
      {
        level: "lvl5",
        id: 5,
        name: "Image gallery",
        karma: 800,
        description:
          "The assignment is to build a JavaScript-powered image gallery, which will test your knowledge of loops, functions, conditionals, and events. You will need to utilize the fundamental building blocks of JavaScript to create a functional image gallery that can be integrated into a website.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Building_blocks/Image_gallery",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-image-gallery.",
      },
      {
        level: "lvl5",
        id: 6,
        name: "Adding features to our bouncing balls demo",
        karma: 800,
        description:
          "For this assessment, you are required to enhance the bouncing balls demo from the previous article by adding new and innovative features to it. The bouncing balls demo will serve as a starting point, and you will need to utilize your creativity and programming skills to implement these new features.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Adding_bouncing_balls_features",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-bouncing-balls-demo.",
      },
      {
        level: "lvl5",
        id: 7,
        name: "YouTube Clone Using React.js",
        karma: 400,
        description:
          "Using React.js buiding a YouTube Clone and style it using Material UI",
        link: "https://www.youtube.com/watch?v=FHTbsZEJspU",
        innner_description: "",
        example:
          "After completing this task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-react-youtube.",
      },
      {
        level: "lvl5",
        id: 8,
        name: "Building a Movie Listing Application",
        karma: 400,
        description:
          "Using the Open Source Library OMDB generate a movie search library or directory",
        link: "https://www.youtube.com/watch?v=b9eMGE7QtTk",
        innner_description: "",
        example:
          "After completing this task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-react-movieapp.",
      },
      {
        level: "lvl5",
        id: 9,
        name: "Building a Bugetting Application with React.js",
        karma: 400,
        description:
          "Create a fully responsive react.js application for bugetting which has forms and helps you track your financial item",
        link: "https://www.youtube.com/playlist?list=PL4cUxeGkcC9iNnY07bh_UPaRIQZcJfARY",
        innner_description: "",
        example:
          "After completing this task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-react-budgetapp.",
      },
    ],

    lvl6: [
      {
        level: "lvl6",
        id: 1,
        name: "Structuring planet data",
        karma: 400,
        description:
          "The table assessment requires you to structure provided data about planets in our solar system into an HTML table. You will need to utilize your HTML skills to create a visually appealing and organized table that accurately represents the provided data. Successful completion of this assessment will demonstrate your ability to structure data in a clear and understandable manner using HTML tables.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/HTML/Tables/Structuring_planet_data",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-structured-planet-data.",
      },
      {
        level: "lvl6",
        id: 2,
        name: "Fundamental layout comprehension",
        karma: 800,
        description:
          "If you have worked through this module then you will have already covered the basics of what you need to know to do CSS layout today, and to work with older CSS as well. This task will test some of your knowledge by the way of developing a simple webpage layout using a variety of techniques.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Fundamental_Layout_Comprehension",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-layout-comprehension.",
      },
      {
        level: "lvl6",
        id: 3,
        name: "Sequencing animations",
        karma: 800,
        description:
          "In this assessment you'll update a page to play a series of animations in a sequence. To do this you'll use some of the techniques we learned in the How to use Promises article.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Asynchronous/Sequencing_animations",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-sequence-animations.",
      },
      {
        level: "lvl6",
        id: 4,
        name: "Client-side web APIs",
        karma: 800,
        description:
          "Build a web application using web APIs such as manipulating documents, fetching data from the server, third-party APIs, drawing graphics, video and audio APIs, and client-side storage. Create an interactive application that fetches data from a third-party API, displays it on the screen, and allows users to interact with it using web APIs. Incorporate client-side storage to persist user data and preferences. Successful completion of this task will demonstrate your proficiency in using web APIs to create engaging web applications.",
        link: "https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Client-side_web_APIs",
        innner_description: "",
        example:
          "After completing your task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-client-side-web-api.",
      },
      {
        level: "lvl6",
        id: 5,
        name: "Chat Room Application",
        karma: 800,
        description:
          "Create a fully functional react.js chat application which supports multiple rooms using react.js and firebase",
        link: "https://www.youtube.com/watch?v=0gLr-pBIPhI",
        innner_description: "",
        example:
          "After completing this task, share the hosted GitHub repository URL in the #web-development channel using the hashtag #cl-react-chatapp.",
      },
    ],
  },
];

export default WebData;
